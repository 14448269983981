import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/1.webp";
import ContactImg3 from "../../assets/img/2.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross, faXmark } from "@fortawesome/free-solid-svg-icons";
import "aos/dist/aos.css";
import AOS from "aos";
export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.subject.trim()) {
      newErrors.subject = "Subject is required";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Handle form submission here
      console.log(formData);
      alert('done')
    }
  };
  AOS.init({
    duration: 800,
  });
  return (
    <Wrapper id="contact" style={{ marginTop: "80px" }}>
      <div className="lightBg">
        <div className="container">
          <HeaderInfo >
            <h1 className="font40 extraBold"data-aos="fade-up">Let's get in touch</h1>
            <p className="font15"data-aos="fade-up">
              If you'd like to get in touch, please feel free to reach out
              through one of the following methods:
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            {/* <div
                style={{ width: "50%" }}
                className="flexNullCenter flexColumn"
              >
                {/* <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox> */}
            {/* </div> */}
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex"data-aos="zoom-in">
              <div style={{ marginTop: "50px" }} >
                <img
                  src={ContactImg3}
                  alt="office"
                  className="radius6"
                  height="400px"
                  width="100%"
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" data-aos="zoom-in">
              <Form>
                <label className="font15">First name:</label>
                <Input>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="font20 extraBold"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && <ErrorMessage><FontAwesomeIcon icon={faXmark}/></ErrorMessage>}
                </Input>
                {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                <br />
                <label className="font15">Email:</label>
                <Input>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className="font20 extraBold"
                    value={formData.email}
                    onChange={handleChange}
                  />
                   {errors.email && <ErrorMessage><FontAwesomeIcon icon={faXmark}/></ErrorMessage>}
                   </Input>
                  {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
               

                <br />
                <label className="font15">Subject:</label>
                <Input>
                  {" "}
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    className="font20 extraBold"
                    value={formData.subject}
                    onChange={handleChange}
                  />
                  {errors.subject && (
                    <ErrorMessage><FontAwesomeIcon icon={faXmark}/></ErrorMessage>
                  )}
                </Input>
                {errors.subject && (
                    <ErrorMessage>{errors.subject}</ErrorMessage>
                  )}
                <br />
                <label>Message</label>
                <Input>
                  <textarea
                    rows="4"
                    cols="50"
                    type="text"
                    id="message"
                    name="message"
                    className="font20 "
                    value={formData.message}
                    onChange={handleChange}
                  />
                  {errors.message && (
                    <ErrorMessage><FontAwesomeIcon icon={faXmark}/></ErrorMessage>
                  )}
                </Input>
                {errors.message && (
                    <ErrorMessage>{errors.message}</ErrorMessage>
                  )}
              </Form>
              <SumbitWrapper className="flex">
                <Button
                  onClick={handleSubmit}
                
                  style={{ maxWidth: "220px" }}
                >
                  submit
                </Button>
              </SumbitWrapper>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 10px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid black;
  background-color: #0b093b;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #007bff;
    border: 1px solid #007bff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px;
  align-self: flex-end;
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
const Input = styled.div`
  font-weight: bold;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const Button = styled.button`
  padding: 12px 24px;
  background-color: #0b093b;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #007bff;
  }
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 0.8em;
  margin: 2px;
`;
