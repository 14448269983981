import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./Header.css";
import backgroundvideo from "../../assets/videos/backvideo.mp4";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
// import HeaderImage from "../../assets/img/header-img.png";
import HeaderImage1 from "../../assets/img/static.jpg";
// import Video from "react-background-video-player";

import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
// import VideoPlayer from "./VideoPlayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import "aos/dist/aos.css";
import AOS from "aos";
export default function Header() {
  AOS.init({
    duration: 800,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [active, setActive] = useState(0);
  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };
  const [show, setshow] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setshow(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const Fadeup = styled.div`
  opacity:${({ show }) => (show ? 1 : 0)}
  transform:${({ show }) => (show ? "translateY(0)" : "translateY(20px)")}
  transition:opacity 0.5s ease,transform 0.5 ease;
  `;
  return (
    <>
      <Wrapper id="home" className="container flexSpaceCenter">
        <LeftSide className="flexCenter">
          <div>
            <h1 className="extraBold font60" data-aos="zoom-out">
              We are Digital Agency.
            </h1>
            <HeaderP className="font15 semiBold" data-aos="fade-up">
              At Digi Flyer, we are passionate about redefining digital
              advertising. In an era where online presence is paramount, we
              strive to empower businesses with innovative and effective digital
              advertising solutions. Our mission is to elevate your brand,
              captivate your audience, and drive measurable results.
            </HeaderP>
            <BtnWrapper data-aos="zoom-out">
              <FullButton title="Get Started" />
            </BtnWrapper>
          </div>
        </LeftSide>
        <RightSide>
          <ImageWrapper>
            <Img
              className="radius8"
              src={HeaderImage1}
              alt="office"
              style={{ zIndex: 9 }}
              data-aos="fade"
            />
            <QuoteWrapper className="flexCenter darkBg radius8">
              <QuotesWrapper data-aos="fade">
                <QuotesIcon />
              </QuotesWrapper>
              <div data-aos="fade-left">
                <p className="font15 whiteColor">
                  <em>
                    Advertising is the bridge between creativity and impact.
                    It's not just about selling a product; it's about telling a
                    compelling story that resonates with your audience and
                    leaves an indelible mark on their minds. In a world
                    inundated with messages, let your brand's voice be the one
                    that stands out, captivates, and inspires action. Because
                    great advertising doesn't just sell; it creates connections,
                    sparks conversations, and builds lasting relationships..
                  </em>
                </p>
                <p
                  className="font15 orangeColor textRight"
                  style={{ marginTop: "10px" }}
                  data-aos="zoom-out"
                >
                  Digi Flyer
                </p>
              </div>
            </QuoteWrapper>
            <DotsWrapper data-aos="fade-left">
              <Dots />
            </DotsWrapper>
          </ImageWrapper>
          <GreyDiv className="lightBg"></GreyDiv>
        </RightSide>
      </Wrapper>
      {/* <VideoPlayer/> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "10px",
        }}
        data-aos="slide-up"
      >
        <Tabs>
          <Tab
            className="semiBold font15 pointer"
            style={{ padding: "10px 15px" }}
            onClick={handleClick}
            active={active === 0}
            id={0}
          >
            For Tablets
          </Tab>
          <Tab
            className="semiBold font15 pointer"
            style={{ padding: "10px 15px" }}
            onClick={handleClick}
            active={active === 1}
            id={1}
          >
            For LCD
          </Tab>
          <Tab
            className="semiBold font13 pointer"
            style={{ padding: "10px 15px" }}
            onClick={handleClick}
            active={active === 2}
            id={2}
          >
            For LED Panel
          </Tab>
        </Tabs>
      </div>
      <>
        <Content active={active === 0}>
          <div class="animated animatedFadeInUp fadeInUp">
            <CardContainer>
              {" "}
              <Container>
                <video
                  style={{ width: "100%", height: "auto" }}
                  src={backgroundvideo}
                  autoPlay
                  loop
                  muted
                />

                <Button>
                  <FontAwesomeIcon icon={faCircle} />
                </Button>
              </Container>
            </CardContainer>
          </div>
        </Content>
        <Content active={active === 1}>
          {/* */}
          <div className="flipper">
            <div id="container">
              <div id="monitor">
                <div id="monitorscreen">
                  <video
                    style={{ width: "100%", height: "auto" }}
                    src={backgroundvideo}
                    autoPlay
                    loop
                    muted
                  />
                </div>
              </div>
            </div>
          </div>
        </Content>
        <Content active={active === 2}>
          <Container>
            <div className="curtain left">
              <div className="curtain right">
                <GridContainer>
                  <Square />
                  <Square />
                  <Square />
                  <Square />
                  <Square />
                  <Square />
                  <Square />
                  <Square />
                  <Square />
                </GridContainer>

                <video
                  style={{ width: "100%", height: "auto" }}
                  src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                  autoPlay
                  loop
                  muted
                />
              </div>
            </div>
          </Container>
        </Content>
      </>
    </>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const Tabs = styled.div`
  overflow: hidden;
  background: #fff;
  font-family: Open Sans;
  height: 3em;
`;

const Tab = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  margin-right: 0.1em;
  font-size: 1em;
  background-color: transparent;
  border-bottom: ${(props) => (props.active ? "none" : "")};
  height: ${(props) => (props.active ? "3em" : "2.6em; top:.4em")};
  transition: background-color 0.5s ease-in-out;
  :hover {
    color: #7620ff;
  }
`;
const Content = styled.div`
  ${(props) => (props.active ? "" : "display:none")}
`;
const CardContainer = styled.div`
  width: 90%;
  max-width: 768px;
  margin: 0 auto;

  border-radius: 25px;

  border: 15px solid black;
  border-right: 30px solid black;
  border-left: 30px solid black;
  margin-bottom: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
`;
const CardContainer2 = styled.div`
  width: 100%;
  max-width: 1124px;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
  border: 10px solid black;
  margin-bottom: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
`;
const Container = styled.div`
  position: relative;
`;
const Button = styled.button`
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);

  z-index: 200;
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 50px;
`;
const GridContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 95%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2px;
  padding:10px
  box-sizing: border-box;
`;
const Square = styled.div`
  position: relative;
  padding-bottom: calc(100% / 3);
  background-color: rgba(0, 0, 0, 0.3);
`;
