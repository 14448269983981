import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.jpg";
import AddImage2 from "../../assets/img/add/2.jpg";
import AddImage3 from "../../assets/img/add/3.webp";
import AddImage4 from "../../assets/img/add/4.jpg";
import ProjectImg7 from "../../assets/img/projects/7.jpg";
import "aos/dist/aos.css";

import AOS from "aos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWatchmanMonitoring } from "@fortawesome/free-brands-svg-icons";
import { faChartLine, faDesktop } from "@fortawesome/free-solid-svg-icons";
AOS.init({
  duration: 800,
});
export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper id="services" style={{ marginTop: "80px" }}>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold" data-aos="fade-right">Our Awesome Services</h1>
            {/* <p className="font15">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p> */}
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              {/* <ServiceBox
                icon="roller"
                title="LCD Panel Solutions"
                subtitle="Transform your physical spaces into captivating visual experiences with our state-of-the-art LCD panel solutions. At Digi Flyer, we provide cutting-edge display solutions tailored to meet the diverse needs of businesses"
              /> */}

              <Container data-aos="fade-up">
                <FontAwesomeIcon
                  icon={faDesktop}
                  style={{ fontSize: "40px" }}
                />
                <Title>LCD Panel Solutions</Title>
                <Description>
                  Transform your physical spaces into captivating visual
                  experiences with our state-of-the-art LCD panel solutions. At
                  Digi Flyer, we provide cutting-edge display solutions
                  tailored to meet the diverse needs of businesses
                </Description>
              </Container>
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              {/* <ServiceBox
                icon="monitor"
                title="Live Monitoring of Ad Analytics"
                subtitle="Empower your advertising strategies with real-time insights and analytics. Our live monitoring services provide a comprehensive view of your ad campaigns, enabling data-driven decisions that maximize your ROI."
              /> */}
              <Container data-aos="fade-up">
                <FontAwesomeIcon
                  icon={faChartLine}
                  style={{ fontSize: "40px" }}
                />
                <Title>Live Monitoring of Ad Analytics</Title>
                <Description>
                  Empower your advertising strategies with real-time insights
                  and analytics. Our live monitoring services provide a
                  comprehensive view of your ad campaigns, enabling data-driven
                  decisions that maximize your ROI.
                </Description>
              </Container>
            </ServiceBoxWrapper>

            <ServiceBoxWrapper>
              {/* <ServiceBox
                icon="roller"
                title="LCD Panel Solutions"
                subtitle="Transform your physical spaces into captivating visual experiences with our state-of-the-art LCD panel solutions. At Digi Flyer, we provide cutting-edge display solutions tailored to meet the diverse needs of businesses"
              /> */}

              <Container data-aos="fade-up">
                <FontAwesomeIcon
                  icon={faDesktop}
                  style={{ fontSize: "40px" }}
                />

                <Title>LCD Panel Solutions</Title>
                <Description>
                  Transform your physical spaces into captivating visual
                  experiences with our state-of-the-art LCD panel solutions. At
                  Digi Flyer, we provide cutting-edge display solutions
                  tailored to meet the diverse needs of businesses
                </Description>
              </Container>
            </ServiceBoxWrapper>

            <ServiceBoxWrapper>
              {/* <ServiceBox
                icon="monitor"
                title="Live Monitoring of Ad Analytics"
                subtitle="Empower your advertising strategies with real-time insights and analytics. Our live monitoring services provide a comprehensive view of your ad campaigns, enabling data-driven decisions that maximize your ROI."
              /> */}
              <Container data-aos="fade-up">
                <FontAwesomeIcon
                  icon={faChartLine}
                  style={{ fontSize: "40px" }}
                />
                <Title>Live Monitoring of Ad Analytics</Title>
                <Description>
                  Empower your advertising strategies with real-time insights
                  and analytics. Our live monitoring services provide a
                  comprehensive view of your ad campaigns, enabling data-driven
                  decisions that maximize your ROI.
                </Description>
              </Container>
            </ServiceBoxWrapper>
            {/* <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Development"
                subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="printer" title="Print" subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor." />
            </ServiceBoxWrapper> */}
          </ServiceBoxRow>
        </div>

        {/* <h5 style={{ color: 'red' }}>Key Features:</h5>
              <ol style={{ listStyleType: 'none' }}>
                <li>Dynamic Displays: Our LCD panels deliver vibrant, high-resolution visuals that grab attention and leave a lasting impression.</li>
                <li>Customizable Content: Tailor your message effortlessly with our easy-to-use content management system. Showcase promotions, announcements, and brand stories in real-time.</li>
                <li>Seamless Integration: Whether it's a storefront, corporate lobby, or event space, our LCD panels seamlessly integrate into any environment.</li>
                <li>Remote Management: Control and update content from anywhere, ensuring your messaging stays current and relevant.</li>
              </ol> */}

        <div className="container">
          <HeaderInfo  >
            <h1 className="font40 extraBold" data-aos="fade-up">What Sets Us Apart</h1>
            <p className="font15" data-aos="slide-up">
              we take pride in what sets us apart from the rest. Our commitment
              to excellence and customer satisfaction distinguishes us in the
              competitive landscape. Here are some key factors that make us
              unique:
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              {/* <ServiceBox
                icon="roller"
                title="Cutting-Edge Technology"
                subtitle="
                Stay ahead in the digital landscape with our cutting-edge technology. We leverage the latest tools and platforms to design bespoke strategies tailored to your unique business objectives."
              /> */}

              <Container data-aos="slide-up">
                <Icon
                  src="https://static.thenounproject.com/png/2095498-200.png"
                  alt="title"
                />
                <Title>Cutting-Edge Technology</Title>
                <Description>
                  {" "}
                  Stay ahead in the digital landscape with our cutting-edge
                  technology. We leverage the latest tools and platforms to
                  design bespoke strategies tailored to your unique business
                  objectives."
                </Description>
              </Container>
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              {/* <ServiceBox
                icon="monitor"
                title="Creative Excellence"
                subtitle="Our team of creative minds crafts compelling campaigns that resonate with your audience. From eye-catching visuals to persuasive ad copies, we ensure every element tells your brand story in a way that captures attention."
              /> */}

              <Container data-aos="slide-up">
                <Icon
                  src="https://cdn-icons-png.flaticon.com/512/5377/5377594.png  "
                  alt="title"
                />
                <Title>Creative Excellence</Title>
                <Description>
                  {" "}
                  Our team of creative minds crafts compelling campaigns that
                  resonate with your audience. From eye-catching visuals to
                  persuasive ad copies, we ensure every element tells your brand
                  story in a way that captures attention.
                </Description>
              </Container>
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              {/* <ServiceBox
                icon="browser"
                title="Data-Driven Strategies"
                subtitle="Harness the power of data with our analytics-driven approach. We analyze, optimize, and refine our strategies based on real-time insights, ensuring your campaigns deliver maximum impact."
              /> */}

              <Container data-aos="slide-up">
                <Icon
                  src="https://cdn-icons-png.flaticon.com/512/4064/4064207.png"
                  alt="title"
                />
                <Title>Data-Driven Strategies</Title>
                <Description>
                  {" "}
                  Harness the power of data with our analytics-driven approach.
                  We analyze, optimize, and refine our strategies based on
                  real-time insights, ensuring your campaigns deliver maximum
                  impact.
                </Description>
              </Container>
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              {/* <ServiceBox
                icon="printer"
                title="Client-Centric Approach"
                subtitle="Your success is our success. We prioritize understanding your business, goals, and challenges to provide personalized solutions that drive growth."
              /> */}
              <Container  data-aos="slide-up">
                <Icon
                  src="https://cdn.iconscout.com/icon/premium/png-256-thumb/customer-centricity-3014580-2504262.png"
                  alt="title"
                />
                <Title>Client-Centric Approach</Title>
                <Description>
                  {" "}
                  Your success is our success. We prioritize understanding your
                  business, goals, and challenges to provide personalized
                  solutions that drive growth.
                </Description>
              </Container>
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg" style={{ padding: "50px 0" }}>
          <div className="container" data-aos="fade-up">
            <ClientSlider />
          </div>
        </div>

        {/* 1st */}
        <div className="lightBg">
          <div className="container" data-aos="zoom-in">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">A few words about company</h4>
                <h2 className="font40 extraBold">A Study of Creativity</h2>
                <p className="font12">
                  Welcome to Digi Flyer, where innovation meets impact! We
                  are a dynamic force in the digital advertising landscape,
                  dedicated to reshaping the way businesses connect with their
                  audiences. Our Vision At Digi Flyer, we envision a world
                  where every brand, big or small, has the tools and strategies
                  to shine brightly in the digital realm. Our passion lies in
                  transforming ideas into campaigns that captivate, engage, and
                  drive results.
                </p>
                <ButtonsRow
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <div style={{ width: "190px" }}>
                    <FullButton
                      title="Get Started"
                      action={() => alert("clicked")}
                    />
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton
                      title="Contact Us"
                      action={() => alert("clicked")}
                      border
                    />
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight >
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>

        {/* 2nd */}
        <div className="lightBg">
          <div className="container"  data-aos="slide-up">
            <Advertising1 className="flexSpaceCenter">
              <AddLeft1>
                <AddLeftInner1>
                  <ImgWrapper1 className="flexCenter">
                    <img className="radius8" src={ProjectImg7} alt="add" />
                  </ImgWrapper1>
                </AddLeftInner1>
              </AddLeft1>
              <AddRight1>
                <h4 className="font15 semiBold">A few words about company</h4>
                <h2 className="font40 extraBold">A Study of Creativity</h2>
                <p className="font12">Ads can be beautiful</p>
                <ButtonsRow1
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <div style={{ width: "190px" }}>
                    <FullButton
                      title="Get Started"
                      action={() => alert("clicked")}
                    />
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton
                      title="Contact Us"
                      action={() => alert("clicked")}
                      border
                    />
                  </div>
                </ButtonsRow1>
              </AddRight1>
            </Advertising1>
          </div>
        </div>

        {/* 3rd */}
        <div className="lightBg">
          <div className="container"  data-aos="slide-up">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">A few words about company</h4>
                <h2 className="font40 extraBold">A Study of Creativity</h2>
                <p className="font12">
                  Welcome to Digi Flyer, where innovation meets impact! We
                  are a dynamic force in the digital advertising landscape,
                  dedicated to reshaping the way businesses connect with their
                  audiences. Our Vision At Digi Flyer, we envision a world
                  where every brand, big or small, has the tools and strategies
                  to shine brightly in the digital realm. Our passion lies in
                  transforming ideas into campaigns that captivate, engage, and
                  drive results.
                </p>
                <ButtonsRow
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <div style={{ width: "190px" }}>
                    <FullButton
                      title="Get Started"
                      action={() => alert("clicked")}
                    />
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton
                      title="Contact Us"
                      action={() => alert("clicked")}
                      border
                    />
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>

        {/* 4th */}
        <div className="lightBg">
          <div className="container"  data-aos="slide-up">
            <Advertising1 className="flexSpaceCenter">
              <AddLeft1>
                <AddLeftInner1>
                  <ImgWrapper1 className="flexCenter">
                    <img className="radius8" src={ProjectImg7} alt="add" />
                  </ImgWrapper1>
                </AddLeftInner1>
              </AddLeft1>
              <AddRight1>
                <h4 className="font15 semiBold">A few words about company</h4>
                <h2 className="font40 extraBold">A Study of Creativity</h2>
                <p className="font12">Ads can be beautiful</p>
                <ButtonsRow1
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <div style={{ width: "190px" }}>
                    <FullButton
                      title="Get Started"
                      action={() => alert("clicked")}
                    />
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton
                      title="Contact Us"
                      action={() => alert("clicked")}
                      border
                    />
                  </div>
                </ButtonsRow1>
              </AddRight1>
            </Advertising1>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
const Advertising1 = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow1 = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft1 = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight1 = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner1 = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper1 = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

const ImageBox = styled.div`
  width: 100%;
  margin-top: 10px;
  max-width: 500px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;

  margin: 10px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${(props) =>
    props.aspectRatio
      ? `${props.aspectRatio}%`
      : "100%"}; /* Maintain aspect ratio */
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio */
  transition: opacity 0.5s ease-in-out; /* Smooth transition */
  opacity: ${(props) => (props.loaded ? "1" : "0")}; /* Hide image initially */
  &:hover {
    opacity: 0.7; /* Reduce opacity on hover */
  }
`;

const Caption = styled.div`
  text-align: center;
  margin-top: 10px;
  color: #888;
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 20px;
  height: 350px;

  border-radius: 10px;
  text-align: center;
  background: linear-gradient(135deg, #7691f5, #74c0fc);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
    background: linear-gradient(135deg, #43569c, #368fff);
  }

  animation: ${fadeIn} 0.5s ease-in-out;
`;

const Icon = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
`;

const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
  color: black;
`;

const Description = styled.p`
  font-size: 14px;
  color: black;
`;

const Container1 = styled.div`
  width: 300px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  opacity: 0; /* Start with opacity 0 for fade-in effect */
`;
