import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
// Components
// import PricingTable from "../Elements/PricingTable";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {faWhatsapp}
import "aos/dist/aos.css";
import AOS from "aos";
export default function Pricing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  AOS.init({
    duration: 800,
  });
  return (
    <Wrapper id="pricing" style={{ marginTop: "80px" }}>
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo data-aos="fade-up" >
            <h1 className="font40 extraBold">
              Check Our Pricing for Video Content
            </h1>
            <p className="font15">
              Explore our flexible pricing plans designed to meet your unique
              needs. Whether you're just getting started or looking for premium
              features, we have the perfect package for you.{" "}
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              {/* <PricingTable
                icon="roller"
                price="29,99/mo"
                title="Starter"
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "Ideal for individuals and small businesses", cheked: true },
                  { name: "Basic features to get you started", cheked: true },
                  { name: "Limited access to advanced functionalities", cheked: true },
                  { name: "Email support during business hours", cheked: true },
                  { name: "Perfect for those on a budget who want to test the waters", cheked: true },
                ]}
                action={() => alert("clicked")}
              /> */}
              <SubscriptionCardWrapper data-aos="fade-up">
                <SubscriptionCard >
                  <Title >Starter Plan</Title>
                  <Title2 >
                    Video Length Upto 30 Seconds
                  </Title2>
                  <div >
                    <Card>
                      <h3>For Tablets</h3>
                      <Price>50 ₹ &nbsp; /screen</Price>
                    </Card>
                    <Card>
                      <h3>For TVs</h3>
                      <Price>75 ₹ &nbsp; /screen</Price>
                    </Card>
                    <Card>
                      <h3>For Led Panels</h3>
                      <Price>100 ₹ &nbsp; /screen</Price>
                    </Card>
                  </div>{" "}
                  <div >
                    <Description>
                      <li> Unlock premium features and content.</li>
                      <li> Ideal for individuals and small businesses</li>
                      <li>Basic features to get you started</li>
                      <li>Limited access to advanced functionalities</li>
                      <li> Email support during business hours</li>
                      <li>
                        Perfect for those on a budget who want to test the
                        waters
                      </li>
                    </Description>

                    <Link to="/contact">
                      <Button>Subscribe Now</Button>
                    </Link>
                    <a
                      href="https://wa.me/+919039203703"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <WhatsAppButton style={{ margin: "15px" }}>
                        <WhatsAppIcon />
                      </WhatsAppButton>
                    </a>
                  </div>
                </SubscriptionCard>
              </SubscriptionCardWrapper>
            </TableBox>
            <TableBox>
              {/* <PricingTable
                icon="monitor"
                price="49,99/mo"
                title="Basic"
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "Great for growing businesses", cheked: true },
                  { name: "Enhanced features for increased productivity", cheked: true },
                  { name: "Access to advanced functionalities", cheked: true },
                  { name: "Priority email support", cheked: true },
                  { name: "Suitable for businesses looking to scale without breaking the bank", cheked: true },
                ]}
                action={() => alert("clicked")}
              /> */}
              <SubscriptionCardWrapper data-aos="fade-up">
                <SubscriptionCard >
                  <Title >Basic Plan</Title>
                  <Title2 >
                    Video Length Upto 45 Seconds
                  </Title2>
                  <div >
                    <Card>
                      <h3>For Tablets</h3>
                      <Price>100 ₹ &nbsp; /screen</Price>
                    </Card>
                    <Card>
                      <h3>For TVs</h3>
                      <Price>150 ₹ &nbsp; /screen</Price>
                    </Card>
                    <Card>
                      <h3>For Led Panels</h3>
                      <Price>200 ₹ &nbsp; /screen</Price>
                    </Card>
                  </div>{" "}
                  <div >
                    <Description>
                      <li> Unlock premium features and content.</li>
                      <li> Ideal for individuals and small businesses</li>
                      <li>Basic features to get you started</li>
                      <li>Limited access to advanced functionalities</li>
                      <li> Email support during business hours</li>
                      <li>
                        Perfect for those on a budget who want to test the
                        waters
                      </li>
                    </Description>

                    <Link to="/contact">
                      <Button>Subscribe Now</Button>
                    </Link>
                    <a
                      href="https://wa.me/+919039203703"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <WhatsAppButton style={{ marginLeft: "15px" }}>
                        <WhatsAppIcon />
                      </WhatsAppButton>
                    </a>
                  </div>
                </SubscriptionCard>
              </SubscriptionCardWrapper>
            </TableBox>
            <TableBox>
              {/* <PricingTable
                icon="browser"
                price="59,99/mo"
                title="Golden"
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "Premium package for enterprises", cheked: true },
                  { name: "All-inclusive features for maximum efficiency", cheked: true },
                  { name: "Priority access to new features and updates", cheked: true },
                  { name: "Dedicated 24/7 customer support", cheked: true },
                  { name: "Tailored solutions for large-scale operations", cheked: true },
                ]}
                action={() => alert("clicked")}
              /> */}
              <SubscriptionCardWrapper data-aos="fade-up">
                <SubscriptionCard >
                  <Title >Golden Plan</Title>
                  <Title2 >
                    Video Length Upto 60 Seconds
                  </Title2>
                  <div >
                    <Card>
                      <h3>For Tablets</h3>
                      <Price>200 ₹ &nbsp; /screen</Price>
                    </Card>
                    <Card>
                      <h3>For TVs</h3>
                      <Price>250 ₹ &nbsp; /screen</Price>
                    </Card>
                    <Card>
                      <h3>For Led Panels</h3>
                      <Price>300 ₹ &nbsp; /screen</Price>
                    </Card>
                  </div>
                  <div >
                    <Description>
                      <li> Unlock premium features and content.</li>
                      <li> Ideal for individuals and small businesses</li>
                      <li>Basic features to get you started</li>
                      <li>Limited access to advanced functionalities</li>
                      <li> Email support during business hours</li>
                      <li>
                        Perfect for those on a budget who want to test the
                        waters
                      </li>
                    </Description>

                    <Link to="/contact">
                      <Button>Subscribe Now</Button>
                    </Link>
                    <a
                      href="https://wa.me/+919039203703"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <WhatsAppButton style={{ marginLeft: "15px" }}>
                        <WhatsAppIcon />
                      </WhatsAppButton>
                    </a>
                  </div>
                </SubscriptionCard>
              </SubscriptionCardWrapper>
            </TableBox>
          </TablesWrapper>
          <HeaderInfo data-aos="fade-up">
            <h1 className="font40 extraBold" >
              Check Our Pricing for Image Content
            </h1>
            <p className="font15" >
              Explore our flexible pricing plans designed to meet your unique
              needs. Whether you're just getting started or looking for premium
              features, we have the perfect package for you.{" "}
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              {/* <PricingTable
                icon="roller"
                price="29,99/mo"
                title="Starter"
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "Ideal for individuals and small businesses", cheked: true },
                  { name: "Basic features to get you started", cheked: true },
                  { name: "Limited access to advanced functionalities", cheked: true },
                  { name: "Email support during business hours", cheked: true },
                  { name: "Perfect for those on a budget who want to test the waters", cheked: true },
                ]}
                action={() => alert("clicked")}
              /> */}
              <SubscriptionCardWrapper data-aos="fade-up">
                <SubscriptionCard >
                  <Title >Starter Plan</Title>
                  <Title2 >
                    Video Length Upto 30 Seconds
                  </Title2>
                  <div >
                    <Card>
                      <h3>For Tablets</h3>
                      <Price>50 ₹ &nbsp; /screen</Price>
                    </Card>
                    <Card>
                      <h3>For TVs</h3>
                      <Price>75 ₹ &nbsp; /screen</Price>
                    </Card>
                    <Card>
                      <h3>For Led Panels</h3>
                      <Price>100 ₹ &nbsp; /screen</Price>
                    </Card>
                  </div>
                  <div >
                    <Description>
                      <li> Unlock premium features and content.</li>
                      <li> Ideal for individuals and small businesses</li>
                      <li>Basic features to get you started</li>
                      <li>Limited access to advanced functionalities</li>
                      <li> Email support during business hours</li>
                      <li>
                        Perfect for those on a budget who want to test the
                        waters
                      </li>
                    </Description>

                    <Link to="/contact">
                      <Button>Subscribe Now</Button>
                    </Link>
                    <a
                      href="https://wa.me/+919039203703"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <WhatsAppButton style={{ margin: "15px" }}>
                        <WhatsAppIcon />
                      </WhatsAppButton>
                    </a>
                  </div>
                </SubscriptionCard>
              </SubscriptionCardWrapper>
            </TableBox>
            <TableBox>
              {/* <PricingTable
                icon="monitor"
                price="49,99/mo"
                title="Basic"
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "Great for growing businesses", cheked: true },
                  { name: "Enhanced features for increased productivity", cheked: true },
                  { name: "Access to advanced functionalities", cheked: true },
                  { name: "Priority email support", cheked: true },
                  { name: "Suitable for businesses looking to scale without breaking the bank", cheked: true },
                ]}
                action={() => alert("clicked")}
              /> */}
              <SubscriptionCardWrapper data-aos="fade-up">
                <SubscriptionCard >
                  <Title >Basic Plan</Title>
                  <Title2 >
                    Video Length Upto 45 Seconds
                  </Title2>
                  <div >
                    <Card>
                      <h3>For Tablets</h3>
                      <Price>100 ₹ &nbsp; /screen</Price>
                    </Card>
                    <Card>
                      <h3>For TVs</h3>
                      <Price>150 ₹&nbsp; /screen</Price>
                    </Card>
                    <Card>
                      <h3>For Led Panels</h3>
                      <Price>200 ₹ &nbsp; /screen</Price>
                    </Card>
                  </div>
                  <div >
                    <Description>
                      <li> Unlock premium features and content.</li>
                      <li> Ideal for individuals and small businesses</li>
                      <li>Basic features to get you started</li>
                      <li>Limited access to advanced functionalities</li>
                      <li> Email support during business hours</li>
                      <li>
                        Perfect for those on a budget who want to test the
                        waters
                      </li>
                    </Description>

                    <Link to="/contact">
                      <Button>Subscribe Now</Button>
                    </Link>
                    <a
                      href="https://wa.me/+919039203703"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <WhatsAppButton style={{ marginLeft: "15px" }}>
                        <WhatsAppIcon />
                      </WhatsAppButton>
                    </a>
                  </div>
                </SubscriptionCard>
              </SubscriptionCardWrapper>
            </TableBox>
            <TableBox>
              {/* <PricingTable
                icon="browser"
                price="59,99/mo"
                title="Golden"
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "Premium package for enterprises", cheked: true },
                  { name: "All-inclusive features for maximum efficiency", cheked: true },
                  { name: "Priority access to new features and updates", cheked: true },
                  { name: "Dedicated 24/7 customer support", cheked: true },
                  { name: "Tailored solutions for large-scale operations", cheked: true },
                ]}
                action={() => alert("clicked")}
              /> */}
              <SubscriptionCardWrapper data-aos="fade-up">
                <SubscriptionCard >
                  <Title >Golden Plan</Title>
                  <Title2 >
                    Video Length Upto 60 Seconds
                  </Title2>
                  <div >
                    <Card>
                      <h3>For Tablets</h3>
                      <Price>200 ₹ &nbsp; /screen</Price>
                    </Card>
                    <Card>
                      <h3>For TVs</h3>
                      <Price>250 ₹ &nbsp; /screen</Price>
                    </Card>
                    <Card>
                      <h3>For Led Panels</h3>
                      <Price>300 ₹ &nbsp; /screen</Price>
                    </Card>
                  </div>
                  <div >
                    <Description>
                      <li> Unlock premium features and content.</li>
                      <li> Ideal for individuals and small businesses</li>
                      <li>Basic features to get you started</li>
                      <li>Limited access to advanced functionalities</li>
                      <li> Email support during business hours</li>
                      <li>
                        Perfect for those on a budget who want to test the
                        waters
                      </li>
                    </Description>

                    <Link to="/contact">
                      <Button>Subscribe Now</Button>
                    </Link>
                    <a
                      href="https://wa.me/+919039203703"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <WhatsAppButton style={{ marginLeft: "15px" }}>
                        <WhatsAppIcon />
                      </WhatsAppButton>
                    </a>
                  </div>
                </SubscriptionCard>
              </SubscriptionCardWrapper>
            </TableBox>
          </TablesWrapper>
          {/* <aos> */}

          {/* </aos> */}
          <Custom  data-aos="fade-up">
            <Title >Having custom requirements contact sales </Title>
            <Title2 >Get your Ads live in 30 Minutes</Title2>
            <Title2 >reach your target audiences faster</Title2>
            <Title2 >go live in minutes</Title2>
            <Title2 >select your area , at your price</Title2>
            <Title2 >fully dynamic infra</Title2>
            <Title2 >live statistics of your ads</Title2>
            {/* <div >
              <Card>
                <h3>For Tablets</h3>
                <Price>50 ₹ &nbsp; /screen</Price>
              </Card>
              <Card>
                <h3>For TVs</h3>
                <Price>75 ₹ &nbsp; /screen</Price>
              </Card>
              <Card>
                <h3>For Led Panels</h3>
                <Price>100 ₹ &nbsp; /screen</Price>
              </Card>
            </div>{" "} */}
            <div >
              {/* <Description>
                <li> Unlock premium features and content.</li>
                <li> Ideal for individuals and small businesses</li>
                <li>Basic features to get you started</li>
                <li>Limited access to advanced functionalities</li>
                <li> Email support during business hours</li>
                <li>
                  Perfect for those on a budget who want to test the waters
                </li>
              </Description> */}

              {/* <Link to="/contact">
                <Button>Subscribe Now</Button>
              </Link> */}
              <a
                href={`https://wa.me/+919039203703?text=${encodeURIComponent("Enquiry About Custom Plan")}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button style={{ margin: "15px" }}>
                  <WhatsAppIcon />
                </Button>
              </a>
            </div>
          </Custom>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;
const fadeIn = keyframes`
  from{
    opacity:0;
  }
   to{
    opacity:1;
   }`;
const SubscriptionCardWrapper = styled.div`
  &:hover {
    transform: translateY(-2px);
  }
`;
// animation: ${fadeIn} 1.5s ease
const SubscriptionCard = styled.div`
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  height: 650px;
  flex-direction: row;
  justify-content: space-between;
  transition: transform 0.3s ease;
`;
const Card = styled.div`
  flex: 1;
  backgroundcolor: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
`;
const Title = styled.h2`
  color: #333333;
  font-size: 24px;
  margin-bottom: 10px;
`;
const Title2 = styled.h2`
  color: #666666;
  font-size: 15px;
  margin-bottom: 10px;
`;

const Price = styled.p`
  color: #7620ff;
  font-size: 15px;
  font-weight: bold;
`;

const Description = styled.p`
  color: #666666;
  font-size: 16px;
  margin-top: 10px;
`;

const Button = styled.button`
  background-color: #0b093b;
  border: none;
  border-radius: 24px;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 20px;
  padding: 12px 24px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #007bff;
  }
`;

const WhatsAppButton = styled.button`
  background-color: #25d366;
  border: none;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #ffffff;
  cursor: pointer;
  font-size: 20px;
  padding: 15px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #128c7e;
  }
`;

const WhatsAppIcon = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg");
  background-size: cover;
`;
const Custom=styled.div`
background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  width: 100%;
  margin: 20px auto;
  transition: transform 0.3s ease;
  @media screen(max-width:768px){
    width:calc(100%-40px)
  }
`
const CustomContent=styled.p`
color: #666666;
font-size: 16px;
margin-top: 10px;
text-align:center;
`