// import React from "react";
// import styled from "styled-components";
// import { Link } from "react-scroll";
// // Assets
// import LogoImg from "../../assets/svg/Logo";

// export default function Contact() {

//   const getCurrentYear = () => {
//     return new Date().getFullYear();
//   }

//   return (
//     <Wrapper>
//       <div className="darkBg">
//         <div className="container">
//           <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
//             <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
//               <LogoImg />
//               <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
//                 Digi Flyer
//               </h1>
//             </Link>
//             <StyleP className="whiteColor font15">
//               © {getCurrentYear()} - <span className="purpleColor font15">Digi Flyer</span> All Right Reserved
//             </StyleP>

//             <Link className="whiteColor animate pointer font15" to="home" smooth={true} offset={-80}>
//               Back to top
//             </Link>
//           </InnerWrapper>
//         </div>
//       </div>
//     </Wrapper>
//   );
// }

// const Wrapper = styled.div`
//   width: 100%;
// `;
// const InnerWrapper = styled.div`
//   @media (max-width: 550px) {
//     flex-direction: column;
//   }
// `;
// const StyleP = styled.p`
//   @media (max-width: 550px) {
//     margin: 20px 0;
//   }
// `;

import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <Link
              className="flexCenter animate pointer"
              to="/"
              smooth={true}
              offset={-80}
            >
              <LogoImg />
              <h1
                className="font15 extraBold whiteColor"
                style={{ marginLeft: "15px" }}
              >
                Digi Flyer
              </h1>
            </Link>

            <FooterSection>
              <TextBox>
                <h2 className="font14 extraBold whiteColor">Contact Us</h2>
                <p className="whiteColor font15">
                  Harshdeep square near nanakheda petrol pump
                  <br /> Ujjain
                </p>
                <p className="whiteColor font15">
                  Email: info@digitalflyer.com
                </p>
                <p className="whiteColor font15">
                  Phone: 7869601983, 8770747501{" "}
                </p>
              </TextBox>
            </FooterSection>

            <FooterSection>
              <TextBox>
                <h2 className="font14 extraBold whiteColor">About Us</h2>
                <p className="whiteColor font15">
                  Learn more about our story and mission.
                </p>
                <Link
                  className="whiteColor animate pointer font15"
                  to="about"
                  smooth={true}
                  offset={-80}
                >
                  Read More
                </Link>
              </TextBox>
            </FooterSection>

            <FooterSection>
              <TextBox>
                <h2 className="font14 extraBold whiteColor">Connect With Us</h2>
                <SocialButton bgColor="#3b5998" hoverColor="#2d4373">
                  <FontAwesomeIcon icon={faFacebook} />
                </SocialButton>
                <SocialButton bgColor="#c13584" hoverColor="#a9336f">
                  <FontAwesomeIcon icon={faInstagram} />
                </SocialButton>
                <SocialButton bgColor="#000000" hoverColor="#403a3a">
                  <FontAwesomeIcon icon={faXTwitter} />
                </SocialButton>
                <SocialButton bgColor="#ff0000" hoverColor="#cc0000">
                  <FontAwesomeIcon icon={faYoutube} />
                </SocialButton>

                <p className="whiteColor font15">
                  Follow us on social media for updates.
                </p>
                {/* Add your social media icons or links here */}
              </TextBox>
            </FooterSection>
            {/*             
            <StyleP className="whiteColor font15">
              © {getCurrentYear()} - <span className="purpleColor font15">Digi Flyer</span> All Right Reserved
            </StyleP> */}

            {/* <Link
              className="whiteColor animate pointer font15"
              to=""
              smooth={true}
            >
              Back to top
            </Link> */}
          </InnerWrapper>

          <StyleP className="whiteColor font15" style={{ textAlign: "center" }}>
            © {getCurrentYear()} -{" "}
            <span className="purpleColor font15">Digi Flyer</span> All Right
            Reserved
          </StyleP>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;

`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;

const FooterSection = styled.div`
  margin-right: 30px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 30px;
  }
`;
const TextBox = styled.div`
  font-size: 20px;
  color: #333;
  text-align: center;
`;
const SocialButton = styled.button`
  margin: 5px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgColor};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  curser: pointer;
  transistion: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
`;
const ButtonText = styled.span`
  margin-left: 10px;
`;
