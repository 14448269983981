import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
// Assets
import ClientLogo01 from "../../assets/img/clients/logo01.svg";
import ClientLogo02 from "../../assets/img/clients/logo02.svg";
import ClientLogo03 from "../../assets/img/clients/logo03.svg";
import ClientLogo04 from "../../assets/img/clients/logo04.svg";
import ClientLogo05 from "../../assets/img/clients/logo05.svg";
import ClientLogo06 from "../../assets/img/clients/logo06.svg";
import google from "../../assets/svg/google.svg";
import kolkata from "../../assets/svg/kolkata.svg";

export default function ClientSlider() {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    arrows: false,
    autoplay: true, 
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          autoplay: true, // Enable autoplay
          autoplaySpeed: 3000, 
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true, // Enable autoplay
          autoplaySpeed: 3000, 
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true, // Enable autoplay
          autoplaySpeed: 3000, 
        },
      },
    ],
  };
  

  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={google} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={kolkata} alt="client logo" />
        </LogoWrapper>

        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo01} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo02} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo03} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo04} alt="client logo" />
        </LogoWrapper>

        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo05} alt="client logo" />
         </LogoWrapper>
         <LogoWrapper className="flexCenter">
           <ImgStyle src={ClientLogo06} alt="client logo" />
         </LogoWrapper>
        {/* <LogoWrapper className="flexCenter">
    //       <ImgStyle src={ClientLogo03} alt="client logo" />
    //     </LogoWrapper>
    //     <LogoWrapper className="flexCenter">
    //       <ImgStyle src={ClientLogo04} alt="client logo" />
    //     </LogoWrapper> */}
        {/* <LogoWrapper className="flexCenter">
    //       <ImgStyle src={ClientLogo01} alt="client logo" />
    //     </LogoWrapper>
    //     <LogoWrapper className="flexCenter">
    //       <ImgStyle src={ClientLogo02} alt="client logo" />
    //     </LogoWrapper> */}
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 100px;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;

const CarouselContainer = styled.div`
  width: 100%;
  height: 100px;
  position: relative; /* Added for positioning buttons */
  overflow: hidden; /* Prevent overflow */
`;

const SlideWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  max-width: 100%;
  overflow: hidden; /* Prevent overflow */
`;

const Slide = styled.div`
  transition: all 0.5s ease;
  overflow: hidden; /* Prevent overflow */
  max-width: 100%;
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
`;

const PrevButton = styled(Button)`
  left: 0;
`;

const NextButton = styled(Button)`
  right: 0;
`;
