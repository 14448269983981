import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import { Route, Routes } from "react-router-dom";
import ScrollButton from "../components/scrollbutton/ScrollButton";
// import ScrollButton from "../components/scrollbutton/ScrollButton";
// import Error from "../components/Sections/Error";


export default function Landing() {
  return (
    <>
     
        {" "}
        <TopNavbar />
        
        <ScrollButton/>
        <Footer />
      

      {/* <Blog /> */}
    </>
  );
}
