import React, { useState, useEffect } from "react";
import "./ScrollButton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";
import "aos/dist/aos.css";
import AOS from "aos";
const ScrollButton = () => {
  const [progress, setProgress] = useState(0);
  AOS.init({
    duration: 1000,
  });
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrolled = (scrollTop / scrollHeight) * 100;
      setProgress(scrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {progress && (
        <button id="backToTopBtn" onClick={scrollToTop} data-aos="zoom-out">
          <FontAwesomeIcon
            style={{ fontSize: "30px", color: "#0b093b" }}
            icon={faArrowAltCircleUp}
          />
          <div className="progressContainer">
            <svg className="progress" viewBox="0 0 100 100">
              <circle className="progress-background" cx="50" cy="50" r="40" />
              <circle
                className="progress-bar"
                cx="50"
                cy="50"
                r="40"
                style={{ strokeDashoffset: 251.2 - (251.2 * progress) / 100 }}
              />
            </svg>
          </div>
        </button>
      )}
    </>
  );
};

export default ScrollButton;
