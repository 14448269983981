import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.jpg";
import ProjectImg2 from "../../assets/img/projects/2.jpg";
import ProjectImg3 from "../../assets/img/projects/3.jpg";
import ProjectImg4 from "../../assets/img/projects/4.jpg";
import ProjectImg5 from "../../assets/img/projects/5.jpg";
import ProjectImg6 from "../../assets/img/projects/6.jpg";
import ProjectImg7 from "../../assets/img/projects/7.jpg";

import AddImage2 from "../../assets/img/add/add2.png";
import "aos/dist/aos.css";
import AOS from "aos";
export default function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  AOS.init({
    duration: 800,
  });
  const aspectRatio = 100 / (16 / 9);
  const [loaded, setLoaded] = useState(false);
  const handleImageLoad = () => {
    setLoaded(true);
  };
  return (
    <Wrapper id="projects" style={{ marginTop: "80px" }}>
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold" data-aos="fade-down">
              Our Awesome Projects
            </h1>
            <h5 className="font20 extraBold" data-aos="fade-up">
              Unleashing the Power of Digital Ads on Outdoor LCD Panels
            </h5>

            <p className="font15" data-aos="fade-right">
              In the fast-paced world of advertising, staying ahead of the curve
              is crucial for businesses looking to captivate their audience.
              Outdoor advertising has undergone a revolutionary transformation
              with the integration of digital ads on LCD panels. This
              cutting-edge approach not only enhances visual appeal but also
              offers unparalleled flexibility and engagement. The Rise of
              Outdoor Digital Advertising: Traditional billboards are evolving
              into dynamic, eye-catching displays that command attention. The
              integration of LCD panels in outdoor advertising spaces brings
              campaigns to life with vibrant colors, high resolutions, and the
              ability to showcase dynamic content. As we explore the realm of
              digital ads on outdoor LCD panels, let's delve into the key
              advantages and trends shaping this innovative advertising
              landscape. <br />
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div
              className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
              style={{ marginTop: "10px" }}
            >
              {/* <ProjectBox
                img={ProjectImg1}
                title="Dynamic Content and Flexibility"
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => alert("clicked")}
              /> */}
              <ImageBox>
                <ImageContainer aspectRatio={aspectRatio} data-aos="fade-up">
                  <Image src={ProjectImg1} alt="img" />
                </ImageContainer>
                <Caption data-aos="zoom-in">
                  Dynamic Content and Flexibility
                </Caption>
              </ImageBox>
            </div>
            <div
              className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
              style={{ marginTop: "10px" }}
            >
              {/* <ProjectBox
                img={ProjectImg2}
                title="High-Resolution Displays"
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => alert("clicked")}
              /> */}
              <ImageBox>
                <ImageContainer aspectRatio={aspectRatio} data-aos="fade-up">
                  <Image src={ProjectImg2} alt="img" />
                </ImageContainer>
                <Caption data-aos="zoom-in">High-Resolution Displays</Caption>
              </ImageBox>
            </div>
            <div
              className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
              style={{ marginTop: "10px" }}
            >
              {/* <ProjectBox
                img={ProjectImg3}
                title="Cost-Effective"
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => alert("clicked")}
              /> */}
              <ImageBox>
                <ImageContainer aspectRatio={aspectRatio} data-aos="fade-up">
                  <Image src={ProjectImg3} alt="img" />
                </ImageContainer>
                <Caption data-aos="zoom-in">Cost-Effective</Caption>
              </ImageBox>
            </div>
          </div>
          <div className="row textCenter">
            <div
              className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
              style={{ marginTop: "10px" }}
            >
              {/* <ProjectBox
                img={ProjectImg4}
                title="Energy-Efficient Displays:"
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => alert("clicked")}
              /> */}
              <ImageBox>
                <ImageContainer aspectRatio={aspectRatio} data-aos="fade-up">
                  <Image src={ProjectImg4} alt="img" />
                </ImageContainer>
                <Caption data-aos="zoom-in">Energy-Efficient Displays</Caption>
              </ImageBox>
            </div>
            <div
              className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
              style={{ marginTop: "10px" }}
            >
              {/* <ProjectBox
                img={ProjectImg5}
                title="Remote Content Management"
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => alert("clicked")}
              /> */}
              <ImageBox>
                <ImageContainer aspectRatio={aspectRatio} data-aos="fade-up">
                  <Image src={ProjectImg5} alt="img" />
                </ImageContainer>
                <Caption data-aos="zoom-in">Remote Content Management</Caption>
              </ImageBox>
            </div>
            <div
              className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
              style={{ marginTop: "10px" }}
            >
              {/* <ProjectBox
                img={ProjectImg6}
                title="Interactivity and Engagement"
                // text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => alert("clicked")}
              /> */}
              <ImageBox>
                <ImageContainer aspectRatio={aspectRatio} data-aos="fade-up" >
                  <Image src={ProjectImg6} alt="img" />
                </ImageContainer>
                <Caption data-aos="zoom-in">
                  Interactivity and Engagement
                </Caption>
              </ImageBox>
            </div>
          </div>
          {/* <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => alert("clicked")} />
            </div>
          </div> */}
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft data-aos="fade-down">
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={ProjectImg7} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight data-aos="fade-down">
              <h4 className="font15 semiBold">A few words about company</h4>
              <h2 className="font40 extraBold">A Study of Creativity</h2>
              <p className="font12">Ads can be beautiful</p>
              <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <div style={{ width: "190px" }}>
                  <FullButton
                    title="Get Started"
                    action={() => alert("clicked")}
                  />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton
                    title="Contact Us"
                    action={() => alert("clicked")}
                    border
                  />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

const ImageBox = styled.div`
  width: 100%;
  margin-top: 10px;
  max-width: 500px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;

  margin: 10px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${(props) =>
    props.aspectRatio
      ? `${props.aspectRatio}%`
      : "100%"}; /* Maintain aspect ratio */
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio */
  transition: opacity 0.5s ease-in-out; /* Smooth transition */
  &:hover {
    opacity: 0.7; /* Reduce opacity on hover */
  }
`;

const Caption = styled.div`
  text-align: center;
  margin-top: 10px;
  color: black;
  font-weight: bold;
`;
