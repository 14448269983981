import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import NotFoundImage from './not_found_image.png'; // Import your image here
import "aos/dist/aos.css";
import AOS from "aos";
const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const NotFoundHeading = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 1rem;
`;

const NotFoundMessage = styled.p`
  font-size: 1.5rem;
  text-align: center;
`;

const NotFoundImageContainer = styled.div`
  width: 80%;
  max-width: 400px;
  margin-bottom: 2rem;
`;

const NotFoundImageStyled = styled.img`
  width: 100%;
  height: auto;
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  padding: 0.5rem 1rem;
  background-color: #0b093b;
  color: #fff;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #007bff;
    color: #fff;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 1rem;
`;
const Error = () => {
  AOS.init({
    duration: 800,
  });
  return (
    <NotFoundContainer>
      <NotFoundHeading data-aos="fade-up">404 Page Not Found</NotFoundHeading>
      <NotFoundMessage data-aos="fade-up">
        Sorry, the page you are looking for does not exist.
      </NotFoundMessage>
      <NotFoundImageContainer data-aos="zoom-out">
        <NotFoundImageStyled
          src="https://img.freepik.com/free-vector/404-error-with-landscape-concept-illustration_114360-7898.jpg?size=626&ext=jpg&ga=GA1.1.1395880969.1710115200&semt=sph"
          alt="404 Not Found"
        />
      </NotFoundImageContainer>
      <ButtonContainer data-aos="zoom-out">
        <StyledLink to="/">Go back to homepage</StyledLink>
      </ButtonContainer>
    </NotFoundContainer>
  );
};

export default Error;
